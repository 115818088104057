import React, { lazy, Suspense, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import firebaseConfig from "./firebaseConfig";
import { Grommet } from "grommet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import theme from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import dayjs from "dayjs";
import "dayjs/locale/de-ch";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import FullscreenLoader from "./components/FullscreenLoader";
import { parse } from "query-string";

const AppRoutes = lazy(() => import("./pages/app/AppRoutes"));
const SiteRoutes = lazy(() => import("./pages/site/SiteRoutes"));
const Consultation = lazy(() =>
  import("./pages/app/consultations/consultation/Consultation")
);

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale("de-ch");

firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.storage();
firebase.analytics();

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(function (err) {
    console.error(err);
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

const Routes = props => {
  const location = useLocation();
  useEffect(() => {
    // If the user landed on the site via a affiliate, save the ID for the registration later.
    const { affiliate } = parse(location.search);
    if (affiliate) {
      window.localStorage.setItem("affiliate", affiliate);
    }
  }, [location.search]);
  return (
    <Switch>
      <Route path={`/consultations/:consultationID`}>
        <Consultation />
      </Route>
      <Route path="/">{props.user ? <AppRoutes /> : <SiteRoutes />}</Route>
    </Switch>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.setState({ user, isLoading: false });
      } else {
        // No user is signed in.
        this.setState({ user: null, isLoading: false });
      }
    });
  }

  render() {
    const { isLoading, user } = this.state;
    if (isLoading) return <FullscreenLoader />;
    return (
      <Grommet theme={theme} background="white">
        <Suspense fallback={<FullscreenLoader />}>
          <Router>
            <ScrollToTop />
            <Routes user={user} />
          </Router>
        </Suspense>
      </Grommet>
    );
  }
}

export default App;
