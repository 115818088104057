export const lightblue = "#A8DADC";
export const blue = "#457C9D";
export const navy = "#1E3557";
export const green = "#289D8F";
export const asphalt = "#274554";
export const yellow = "#FFC045";
export const salmon = "#EBB080";
export const orange = "#E86F51";
export const violet = "#8D497D";
export const petrol = "#17687A";
export const red = "#FF3333";

export const brandColor = "#6647FF";

const theme = {
  global: {
    font: {
      family: "Roboto, sans-serif",
      weight: "400",
    },
    colors: {
      brand: {
        light: brandColor,
        dark: brandColor,
      },
      focus: "brand",
      border: {
        light: "rgba(0,0,0,0.15)",
      },
      "accent-1": "#47FF8412",
    },
    input: {
      weight: 400,
    },
    // control: {
    //   disabled: {
    //     opacity: 1,
    //     color: "dark-1",
    //   },
    // },
  },
  accordion: {
    heading: {
      level: 2,
    },
    border: undefined,
  },
  anchor: {
    color: {
      light: "brand",
      dark: "brand",
    },
  },
  button: {
    border: {
      radius: "30px",
      width: "1px",
    },
    size: {
      small: {
        border: {
          radius: "30px",
        },
      },
      large: {
        border: {
          radius: "30px",
        },
      },
    },
    color: {
      dark: "white",
      light: "white",
    },
    primary: {
      color: "brand",
      extend: props => ({
        color: "white",
        fontWeight: 400,
      }),
    },
    extend: ({ primary, plain, background }) => ({
      ...(!primary && { color: brandColor }),
    }),
  },
  formField: {
    border: {
      side: null,
    },
    help: {
      size: "small",
    },
  },
  heading: {
    weight: 700,
    // font: {
    //   family: "Lora, serif",
    // },
  },
  maskedInput: {
    extend: ({ noBorder }) =>
      noBorder && {
        border: "none",
      },
  },
  table: {
    header: {
      border: null,
      pad: { horizontal: "medium", vertical: "small" },
    },
    body: {
      border: null,
      pad: { horizontal: "medium", vertical: "small" },
    },
    footer: {
      pad: { horizontal: "medium", vertical: "small" },
    },
  },
  tabs: {
    extend: props => ({ flexDirection: "column-reverse" }),
    header: {
      direction: "column-reverse",
    },
  },
  textInput: {
    disabled: {
      opacity: 1,
    },
  },
  textArea: {
    disabled: {
      opacity: 1,
    },
  },
};

export default theme;
