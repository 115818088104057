const firebaseConfig = {
  apiKey: "AIzaSyCAZn_vDvXLc6TjAInZ0RLHRQoDYK2NVzI",
  authDomain: "vorsorge360.firebaseapp.com",
  databaseURL: "https://vorsorge360.firebaseio.com",
  projectId: "vorsorge360",
  storageBucket: "vorsorge360.appspot.com",
  messagingSenderId: "364239444942",
  appId: "1:364239444942:web:43cf5b1a2e986782d9e99f",
  measurementId: "G-7RWDZHG9KT",
};
export default firebaseConfig;
