import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { Box } from "grommet";

export default function FullscreenLoader(props) {
  return (
    <Box width="100vw" height="100vh" align="center" justify="center">
      <MoonLoader {...props} />
    </Box>
  );
}
